import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Button, makeStyles, tokens, useId, Title3, Input, Spinner } from '@fluentui/react-components';
import TypeWriterEffect from 'react-typewriter-effect';

const useStyles = makeStyles({
  base: {
    display: 'flex',
    flexDirection: 'column',
    '& > label': {
      marginBottom: tokens.spacingVerticalMNudge
    }
  }
});

function Prompt() {
  const [responseText, setResponseText] = useState("");
  const [promptText, promptChange] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //creating IP state
  const [geoData, setGeoData] = useState({});

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setGeoData(res.data);
  }

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()

  }, [])

  const handleChange = (event) => {
    promptChange(event.target.value);
  };

  const handleSubmit = (event) => {
    event && event.preventDefault();

    setIsLoading(true);
    (async () => {
      const bodyJSON = JSON.stringify({
        promptText: promptText,
        city: geoData.city ? geoData.city : '',
        state: geoData.state ? geoData.state : '',
        zip_code: geoData.postal ? geoData.postal : '',
        country: geoData.country_name ? geoData.country_name : '',
        latitude: geoData.latitude ? geoData.latitude : '',
        longitude: geoData.longitude ? geoData.longitude : '',
      });

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: bodyJSON
      }

      const completion = await (await fetch(`/api/GetOpenAICompletionResponse`, requestOptions)).json();
      setResponseText(completion.text);
      setIsLoading(false);
    })();
  };

  const inputId = useId('input');
  const styles = useStyles();

  return (<div style={{ width: '80%' }}>

    <div className={styles.base}>
      <Title3 htmlFor={inputId}>Ask me a question!</Title3>
      (e.g. What is your favorite subject in school?)
      <Input
        appearance="outline"
        value={promptText}
        onChange={handleChange}
        size="large"
        type="text"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
        id={inputId}
        style={{ marginTop: '5px' }}
      />
    </div>

    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
      <Button
        onClick={handleSubmit}
        appearance="outline"
      >Submit</Button>
    </div>
    {isLoading ? <Spinner label="I'm thinking..." /> : responseText &&
      <TypeWriterEffect
        textStyle={{ fontFamily: 'Orbitron', fontSize: '1em' }}
        startDelay={100}
        cursorColor="white"
        text={responseText}
        typeSpeed={25}
        hideCursorAfterText
      />
    }
  </div >
  );
}

export default Prompt;