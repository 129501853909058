
import React from "react";
import { makeStyles, LargeTitle } from '@fluentui/react-components';
import Prompt from "./Prompt.js";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    margin: '30px auto 0 auto',
    height: '50vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
})

function App() {
  const classes = useStyles();

  return <div className={classes.root}>
    <div className="flex-container center">
      <LargeTitle>
        Charlie Landers
      </LargeTitle>
    </div>
    <Prompt />
  </div>;
}

export default App;